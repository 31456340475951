import React from "react"
import { Link } from "gatsby"
export default function Home() {
  return(
    <div style={{color:"green"}}>
      <Link to="/contact/">Contact</Link>
      <h1>Salut Mihai</h1>
      <p>Azi am avut o zi frumoasa!</p>
      <img src="https://source.unsplash.com/random/400x200" alt=""/>
    </div>
  );
}
